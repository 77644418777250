<template>
  <fw-layout back-to="/manage" management mobile-ready>
    <template #main-content>
      <fw-panel title="Gestão de mapas de férias">
        <fw-panel-info v-if="!loading && (!years || !years.length)" type="basic" class="text-center my-5 text-gray-500">
          Sem mapas associados
        </fw-panel-info>
        <LoadingPlaceholder v-else-if="loading" :list="false" />
        <div v-else class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-5">
          <a
            v-for="(item, index) in years"
            :key="index"
            :href="openMapPath(item)"
            class="group bg-gray-200 flex-col flex gap-4 p-5 rounded-xl hover:bg-opacity-70"
          >
            <div class="flex gap-3 justify-between items-center">
              <div>
                <fw-icon-calendar-event class="w-6 h-6" />
              </div>
              <div class="font-bold uppercase flex-1 text-lg">
                {{ item }}
              </div>
            </div>
            <div class="flex-1 flex flex-col justify-end gap-2">
              <div class="flex flex-col gap-2 font-semibold text-gray-500 text-sm">
                <div class="flex flex-col gap-0.5">
                  <div>Equipas</div>
                  <div class="flex gap-2">
                    <div v-if="count.mine?.[item].maps" class="flex gap-0.5 items-center">
                      <fw-icon-star class="w-4 h-4 flex-shrink-0" /> {{ count.mine?.[item].maps }}
                    </div>
                    <div v-if="isManagerOrAdmin" class="flex gap-0.5 items-center">
                      <fw-icon-settings class="w-4 h-4 flex-shrink-0" /> {{ count.global?.[item].maps }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-col gap-0.5">
                  <div>Trabalhadores</div>
                  <div class="flex gap-2">
                    <div v-if="count.mine?.[item].maps_users" class="flex gap-0.5 items-center">
                      <fw-icon-star class="w-4 h-4 flex-shrink-0" /> {{ count.mine?.[item].maps_users }}
                    </div>
                    <div v-if="isManagerOrAdmin" class="flex gap-0.5 items-center">
                      <fw-icon-settings class="w-4 h-4 flex-shrink-0" /> {{ count.global?.[item].maps_users }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </fw-panel>
    </template>
  </fw-layout>
</template>

<script>
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    LoadingPlaceholder
  },

  data() {
    return {
      loading: false,
      years: [],
      count: {}
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },
    user() {
      return this.$store.getters.getUser
    },
    language() {
      return this.$store.state.language
    },
    userPermissions() {
      return this.$store.getters.userPermissions
    },
    isManagerOrAdmin() {
      return this.userPermissions.isManager || this.userPermissions.isAdmin
    }
  },

  mounted() {
    this.getManagerMapsStats()
  },

  methods: {
    async getManagerMapsStats() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getManagerMapsStats()
        //console.log('getManagerMapsStats :>> ', response)
        this.years = response.years.sort((a, b) => b - a)
        this.count = response.count
      })

      this.loading = false
    },

    openMapPath(item) {
      return this.$router.resolve({
        name: 'manage-vacations-year',
        params: { year: item }
      }).href
    }
  }
}
</script>
